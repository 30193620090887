
const useAuth = () => {
  try {
    const token = localStorage.getItem('token')
    const newRole = localStorage.getItem('newRole')

    if (token) {
      if (newRole) {
        return {
          auth: true,
          role: newRole
        }
      }
      else {
        const tokenPayload = token.split('.')[1];
        const adjustedTokenPayload = tokenPayload.replace(/-/g, '+').replace(/_/g, '/');

        // Adding padding if needed
        const paddedTokenPayload = adjustedTokenPayload.padEnd(adjustedTokenPayload.length + (4 - adjustedTokenPayload.length % 4) % 4, '=');

        // Decoding the base64Url-encoded payload
        const decodedPayload = atob(paddedTokenPayload);
        const user = JSON.parse(decodedPayload);
        const issuedAt = user.iat; // issued-at time
      const expirationTime = issuedAt + 7 * 24 * 60 * 60; // 
      const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
      if (currentTime > expirationTime) {
        console.log("Token has expired");
        localStorage.clear(); // Clears all localStorage items
        window.location.href = '/';
        return {
          auth: false,
          role: null,
        };
      }
        console.log("While Getting role data is",user)
        return {
          auth: true,
          role: user.Role
        }
      }


    } else {
      return {
        auth: false,
        role: null
      }
    }
  }
  catch(error) {
    console.log(error)
    localStorage.removeItem('token');
    localStorage.removeItem('newRole');
    window.location.href = '/';
  }
}
export default useAuth