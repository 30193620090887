/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Calendar } from 'primereact/calendar';
import { MultiSelect } from 'primereact/multiselect';
import { Dialog } from 'primereact/dialog';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import api from '../../../Authenticaltion/api';
import { FaExchangeAlt } from 'react-icons/fa';  // Import icons
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { saveAs } from 'file-saver';
import './ClockifyViewPage.css';

const ClockifyViewPage = () => {
  const [timeEntries, setTimeEntries] = useState([]);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [userPage, setUserPage] = useState(1);
  const [hasMoreUsers, setHasMoreUsers] = useState(true);
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  const [userSearchQuery, setUserSearchQuery] = useState('');
  const [projects, setProjects] = useState([]);
  const [projectPage, setProjectPage] = useState(1);
  const [hasMoreProjects, setHasMoreProjects] = useState(true);
  const [isLoadingProjects, setIsLoadingProjects] = useState(false);
  const [projectSearchQuery, setProjectSearchQuery] = useState('');
  const [totals, setTotals] = useState({});
  const [filters, setFilters] = useState({
    userIds: [],
    projectIds: [],
    startDate: null,
    endDate: null,
  });
  const [paginationModel, setPaginationModel] = useState({
    page: 0,  // 0-based page number
    pageSize: 25,  // Default page size
  });
  const [pageCount, setPageCount] = useState(0);
  const [totalEntries, setTotalEntries] = useState(0);
  const [workspaceId, setWorkspaceId] = useState(null);  // State to hold the workspace ID
  const [workspaces, setWorkspaces] = useState([]);  // State to hold the list of workspaces
  const [selectedWorkspace, setSelectedWorkspace] = useState(null);  // Add this line
  const [selectedEntries, setSelectedEntries] = useState([]); 
  const [isGeneratingReport, setIsGeneratingReport] = useState(false);
  const [showExportDropdown, setShowExportDropdown] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredTimeEntries, setFilteredTimeEntries] = useState([]);
  const [selectedTask, setSelectedTask] = useState(null);
  const [showTaskDialog, setShowTaskDialog] = useState(false);
  const [userProfiles, setUserProfiles] = useState({});

  const apiKey = 'NWVkN2U1NTMtOGYxOC00NmM1LWIzOTgtOGYzOGI0NjM1MTZj';

  // Fetch Workspace ID dynamically
  const fetchWorkspaceId = async () => {
    try {
      const response = await api.get(
        '/getAllWorkspaces',  // Get the list of workspaces
      );
      setWorkspaces(response.data);
      if (response.data && response.data.length > 0) {
        const firstWorkspace = response.data[0];
        setWorkspaceId(firstWorkspace.ClockifyWorkSpaceId);
        setSelectedWorkspace(firstWorkspace); // Set the selected workspace
      }
    } catch (error) {
      console.error('Error fetching workspace ID:', error);
    }
  };

  // Fetch Users for the Filter
  const fetchUsers = async (page = 1, append = false) => {
    if (!workspaceId || isLoadingUsers || (!hasMoreUsers && append)) return;

    setIsLoadingUsers(true);
    try {
      const payload = {
        searchValue: userSearchQuery || "",
        page: page,
        pageSize: 50,
        statuses: ["ACTIVE", "PENDING"],
        excludeIds: [],
        forApproval: false,
        forceFilter: false,
        ignoreFilter: false,
        reportType: "SUMMARY"
      };

      const response = await axios.post(
        `https://global.api.clockify.me/users/workspaces/${workspaceId}/report-filters/team`,
        payload,
        { headers: { 'X-Api-Key': apiKey } }
      );

      const transformedUsers = response.data.idNamePairs.map(user => ({
        id: user.id,
        name: user.name,
        status: user.status
      }));

      if (append) {
        setUsers(prev => [...prev, ...transformedUsers]);
      } else {
        setUsers(transformedUsers);
      }

      setHasMoreUsers(transformedUsers.length === 50);
      setUserPage(page);
    } catch (error) {
      console.error('Error fetching users:', error);
    } finally {
      setIsLoadingUsers(false);
    }
  };

  const onLazyLoad = (event) => {
    const lastScrollPos = event.last;
    const totalItems = users.length;

    if (lastScrollPos === totalItems - 1 && !isLoadingUsers && hasMoreUsers) {
      fetchUsers(userPage + 1, true);
    }
  };

  // Handle user search
  const handleUserSearch = (event) => {
    setUserSearchQuery(event.query);
    setUserPage(1);
    setHasMoreUsers(true);
    fetchUsers(1, false);
  };

  // Fetch Projects for the Filter
  const fetchProjects = async (page = 1, append = false) => {
    if (!workspaceId || isLoadingProjects || (!hasMoreProjects && append)) return;

    setIsLoadingProjects(true);
    try {
      const payload = {
        name: projectSearchQuery || "",
        status: "ACTIVE",
        page: page,
        pageSize: 50,
        clients: null
      };

      const response = await axios.post(
        `https://global.api.clockify.me/workspaces/${workspaceId}/projects/report-filters`,
        payload,
        { headers: { 'X-Api-Key': apiKey } }
      );

      // Transform the projects data
      const transformedProjects = response.data.map(project => ({
        id: project.id,
        name: project.name,
        color: project.color
      }));

      if (append) {
        setProjects(prev => [...prev, ...transformedProjects]);
      } else {
        setProjects(transformedProjects);
      }

      setHasMoreProjects(transformedProjects.length === 50);
      setProjectPage(page);
    } catch (error) {
      console.error('Error fetching projects:', error);
    } finally {
      setIsLoadingProjects(false);
    }
  };

  const onProjectLazyLoad = (event) => {
    const lastScrollPos = event.last;
    const totalItems = projects.length;

    if (lastScrollPos === totalItems - 1 && !isLoadingProjects && hasMoreProjects) {
      fetchProjects(projectPage + 1, true);
    }
  };

  // Handle project search
  const handleProjectSearch = (event) => {
    setProjectSearchQuery(event.query);
    setProjectPage(1);
    setHasMoreProjects(true);
    fetchProjects(1, false);
  };

  // Fetch Time Entries based on filters and pagination
  const fetchReportData = async (page = 1, pageSize = 25) => {
    if (!workspaceId) return;  // Wait for workspaceId to be set
    setLoading(true);
    try {
      const response = await axios.post(
        `https://reports.api.clockify.me/v1/workspaces/${workspaceId}/reports/detailed`,
        {
          dateRangeStart: filters.startDate.toISOString(),
          dateRangeEnd: filters.endDate.toISOString(),
          detailedFilter: { sortColumn: 'DATE', page, pageSize },
          users: filters.userIds.length > 0 ? { contains: 'CONTAINS', ids: filters.userIds } : null,
          projects: filters.projectIds.length > 0 ? { contains: 'CONTAINS', ids: filters.projectIds } : null,
        },
        {
          headers: { 'X-Api-Key': apiKey, 'Content-Type': 'application/json' },
        }
      );

      const timeEntriesData = response.data.timeentries || [];
      const totalEntriesCount = response.data.totals[0].entriesCount || 0;

      setTimeEntries(timeEntriesData);
      setTotals(response.data.totals[0] || {});
      setTotalEntries(totalEntriesCount);

      setPageCount(Math.ceil(totalEntriesCount / paginationModel.pageSize));
    } catch (error) {
      console.error('Error fetching report data:', error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch workspace users with profile pictures
  const fetchWorkspaceUsers = async () => {
    if (!workspaceId) return;
    try {
      const response = await axios.get(
        `https://api.clockify.me/api/v1/workspaces/${workspaceId}/users`,
        { headers: { 'X-Api-Key': apiKey } }
      );

      const profiles = {};
      response.data.forEach(user => {
        profiles[user.id] = user.profilePicture;
      });
      setUserProfiles(profiles);
    } catch (error) {
      console.error('Error fetching workspace users:', error);
    }
  };

  // Modify workspace switching function to cycle through workspaces
  const handleWorkspaceSwitch = () => {
    if (workspaces.length <= 1) return;

    const currentIndex = workspaces.findIndex(w => w.ClockifyWorkSpaceId === workspaceId);
    const nextIndex = (currentIndex + 1) % workspaces.length;
    const nextWorkspace = workspaces[nextIndex];

    setWorkspaceId(nextWorkspace.ClockifyWorkSpaceId);
    setSelectedWorkspace(nextWorkspace);
    // Reset pagination to first page when switching workspace
    setPaginationModel({
      page: 0,
      pageSize: 25
    });
    // Clear filters when switching workspace
    const endDate = new Date();
    const startDate = new Date();
    startDate.setDate(endDate.getDate() - 7);
    setFilters({
      userIds: [],
      projectIds: [],
      startDate: startDate,
      endDate: endDate,
    });
  };

  // Function to generate and download CSV
  const generateCSV = (data) => {
    const headers = ['Date', 'User', 'Project', 'Description', 'Duration'];
    const csvData = data.map(entry => [
      new Date(entry.timeInterval.start).toLocaleDateString(),
      entry.userName,
      entry.projectName,
      entry.description,
      entry.timeInterval.duration
    ]);

    const csvContent = [
      headers.join(','),
      ...csvData.map(row => row.join(','))
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
    saveAs(blob, 'time_entries_report.csv');
  };

  // Function to generate and download PDF
  const generatePDF = (data) => {
    const doc = new jsPDF();
    const logoPath = '/images/LogoSpc.png';
    
    // Add logo
    const img = new Image();
    img.src = process.env.PUBLIC_URL + logoPath;
    
    // Once image is loaded, add it to PDF
    img.onload = function() {
      // Calculate page width and logo dimensions
      const pageWidth = doc.internal.pageSize.width;
      const imgWidth = 30;
      const imgHeight = (img.height * imgWidth) / img.width;
      
      // Position logo on the right side (pageWidth - imgWidth - margin)
      doc.addImage(img, 'PNG', pageWidth - imgWidth - 10, 10, imgWidth, imgHeight);
      
      // Add title on the left side
      doc.setFontSize(16);
      doc.text('Time Entries Report', 14, 25);
      
      // Add current date
      doc.setFontSize(10);
      doc.text(`Generated on: ${new Date().toLocaleDateString()}`, 14, 32);

      const tableData = data.map(entry => [
        new Date(entry.timeInterval.start).toLocaleDateString(),
        entry.userName,
        entry.projectName,
        entry.description,
        calculateDuration(entry.timeInterval.start, entry.timeInterval.end)
      ]);

      doc.autoTable({
        head: [['Date', 'User', 'Project', 'Description', 'Duration']],
        body: tableData,
        startY: 45,
        styles: {
          fontSize: 9,
          cellPadding: 3
        },
        columnStyles: {
          0: { cellWidth: 25 }, // Date
          1: { cellWidth: 35 }, // User
          2: { cellWidth: 35 }, // Project
          3: { cellWidth: 60 }, // Description
          4: { cellWidth: 25 } // Duration
        },
        headStyles: {
          fillColor: [28, 143, 252],
          textColor: 255,
          fontSize: 10,
          fontStyle: 'bold'
        }
      });

      doc.save('time_entries_report.pdf');
    };

    img.onerror = function() {
      // If logo fails to load, generate PDF without it
      console.error('Failed to load logo image');
      doc.setFontSize(16);
      doc.text('Time Entries Report', 14, 15);
      
      doc.setFontSize(10);
      doc.text(`Generated on: ${new Date().toLocaleDateString()}`, 14, 25);

      const tableData = data.map(entry => [
        new Date(entry.timeInterval.start).toLocaleDateString(),
        entry.userName,
        entry.projectName,
        entry.description,
        calculateDuration(entry.timeInterval.start, entry.timeInterval.end)
      ]);

      doc.autoTable({
        head: [['Date', 'User', 'Project', 'Description', 'Duration']],
        body: tableData,
        startY: 35,
        styles: {
          fontSize: 9,
          cellPadding: 3
        },
        columnStyles: {
          0: { cellWidth: 25 },
          1: { cellWidth: 35 },
          2: { cellWidth: 35 },
          3: { cellWidth: 60 },
          4: { cellWidth: 25 }
        },
        headStyles: {
          fillColor: [28, 143, 252],
          textColor: 255,
          fontSize: 10,
          fontStyle: 'bold'
        }
      });

      doc.save('time_entries_report.pdf');
    };
  };

  // Function to handle report generation
  const handleGenerateReport = async (format) => {
    setIsGeneratingReport(true);
    try {
      // Fetch all data for report
      const response = await axios.post(
        `https://reports.api.clockify.me/v1/workspaces/${workspaceId}/reports/detailed`,
        {
          dateRangeStart: filters.startDate.toISOString(),
          dateRangeEnd: filters.endDate.toISOString(),
          detailedFilter: { sortColumn: 'DATE', page: 1, pageSize: 1000 },
          users: filters.userIds.length > 0 ? { contains: 'CONTAINS', ids: filters.userIds } : null,
          projects: filters.projectIds.length > 0 ? { contains: 'CONTAINS', ids: filters.projectIds } : null,
        },
        {
          headers: { 'X-Api-Key': apiKey, 'Content-Type': 'application/json' },
        }
      );

      const reportData = response.data.timeentries || [];

      if (format === 'csv') {
        generateCSV(reportData);
      } else if (format === 'pdf') {
        generatePDF(reportData);
      }
    } catch (error) {
      console.error('Error generating report:', error);
      alert('Error generating report. Please try again.');
    } finally {
      setIsGeneratingReport(false);
    }
  };



  const clearFilters = () => {
    const endDate = new Date();
    const startDate = new Date();
    startDate.setDate(endDate.getDate() - 7);  // Reset to default date range (last 7 days)

    setFilters({
      userIds: [],
      projectIds: [],
      startDate: startDate,
      endDate: endDate,
    });
  };

  useEffect(() => {
    fetchWorkspaceId();  // Fetch the workspace ID when the component mounts
  }, []);

  useEffect(() => {
    if (workspaceId) {
      fetchUsers();  // Fetch users once workspaceId is available
      fetchProjects();  // Fetch projects once workspaceId is available
      fetchWorkspaceUsers();
      const endDate = new Date();
      const startDate = new Date();
      startDate.setDate(endDate.getDate() - 7);  // Set the default date range (last 7 days)

      setFilters((prev) => ({
        ...prev,
        startDate,
        endDate,
      }));
    }
  }, [workspaceId]);  // Run when workspaceId changes

  useEffect(() => {
    if (workspaceId) {
      fetchReportData(paginationModel.page + 1, paginationModel.pageSize);  // Fetch report data when pagination model or filters change
    }
  }, [paginationModel, filters, workspaceId]);

  useEffect(() => {
    if (timeEntries.length > 0) {
      const filtered = timeEntries.filter(entry =>
        entry.userName?.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredTimeEntries(filtered);
    } else {
      setFilteredTimeEntries([]);
    }
  }, [searchQuery, timeEntries]);

  const handlePageChange = (newPage) => {
    setPaginationModel((prev) => ({
      ...prev,
      page: newPage,
    }));
  };
  const handleFilterChange = (field, value) => {
    setFilters((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  // Calculate duration between start and end time
  const calculateDuration = (start, end) => {
    const startTime = new Date(start);
    const endTime = new Date(end);
    const diff = endTime - startTime;

    const hours = Math.floor(diff / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));

    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:00`;
  };


  return (
    <div>
      <div className="header-marquee">
        <marquee behavior="scroll" direction="left" scrollamount="5">
          Displaying data for the last 7 days by default. You can change the date range if needed.
        </marquee>
      </div>

      <div className="container-fluid">

        <div>
          <div className="table-responsive">
            <div className="clockify-header">
              <div className="totals-section">
                <div className="user">
                  <MultiSelect
                    value={filters.userIds}
                    options={users}
                    onChange={(e) => setFilters({ ...filters, userIds: e.value })}
                    optionLabel="name"
                    optionValue="id"
                    placeholder="Select Users"
                    className="multiselect-custom"
                    display="chip"
                    filter
                    filterBy="name"
                    onFilter={handleUserSearch}
                    onShow={() => {
                      setUserPage(1);
                      setHasMoreUsers(true);
                      setUserSearchQuery('');
                      fetchUsers(1);
                    }}
                    virtualScrollerOptions={{
                      lazy: true,
                      onLazyLoad: onLazyLoad,
                      itemSize: 40,
                      loading: isLoadingUsers,
                      delay: 150,
                      showLoader: false,
                      loadingTemplate: null
                    }}
                  />
                </div>
                <div className="project">
                  <MultiSelect
                    value={filters.projectIds}
                    options={projects}
                    onChange={(e) => setFilters({ ...filters, projectIds: e.value })}
                    optionLabel="name"
                    optionValue="id"
                    placeholder="Select Projects"
                    className="multiselect-custom"
                    display="chip"
                    filter
                    filterBy="name"
                    onFilter={handleProjectSearch}
                    onShow={() => {
                      setProjectPage(1);
                      setHasMoreProjects(true);
                      setProjectSearchQuery('');
                      fetchProjects(1);
                    }}
                    virtualScrollerOptions={{
                      lazy: true,
                      onLazyLoad: onProjectLazyLoad,
                      itemSize: 40,
                      loading: isLoadingProjects,
                      delay: 150,
                      showLoader: false,
                      loadingTemplate: null
                    }}
                    itemTemplate={(option) => (
                      <div className="project-option" style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px'
                      }}>
                        <span style={{
                          width: '12px',
                          height: '12px',
                          borderRadius: '50%',
                          backgroundColor: option.color || '#607D8B',
                          display: 'inline-block'
                        }}></span>
                        <span>{option.name}</span>
                      </div>
                    )}
                  />
                </div>
                <div className="d-flex align-items-center">
                  <Calendar
                    value={filters.startDate}
                    onChange={(e) => handleFilterChange('startDate', e.value)}
                    className="me-2"
                    showIcon
                  />
                  <Calendar
                    value={filters.endDate}
                    onChange={(e) => handleFilterChange('endDate', e.value)}
                    className="me-2"
                    showIcon
                  />
                </div>
              </div>


              <div className="actions-section">
                <button className="header-button1">
                  <a
                    className="dropdown-item"
                    onClick={() => {
                      handleGenerateReport('pdf');
                      setShowExportDropdown(false);
                    }}
                    style={{ color: 'black' }}
                  >
                    <i className="far fa-file-pdf me-2" style={{ color: 'black' }}>
                    </i> Export</a></button>
                <button className="header-button1">

                  <a
                    className="dropdown-item"
                    onClick={() => {
                      handleGenerateReport('csv');
                      setShowExportDropdown(false);
                    }}
                    style={{ color: 'black' }}
                  >
                    <i className="far fa-file-excel me-2" style={{ color: 'black' }}></i>CSV

                  </a>   </button>
                <div className="toggle-group">
                  <button
                    className="btn btn-outline-secondary ms-2"
                    onClick={clearFilters}
                    title="Clear Filters"
                  >
                    <i className="fas fa-times me-1" style={{ color: 'black' }}></i>

                  </button>
                </div>
                <div className="d-flex align-items-center" style={{ color: 'black' }}>
                  <button
                    className="btn btn-outline-secondary d-flex align-items-center gap-2"
                    onClick={handleWorkspaceSwitch}
                    title="Switch Workspace"
                    style={{ borderRadius: '50%', width: '40px', height: '40px', padding: '8px', color: 'black', display: 'flex', justifyContent: 'center' }}
                  >
                    <FaExchangeAlt />
                  </button>
                  {selectedWorkspace && (
                    <span className="ms-2" style={{ color: 'black' }}>
                      {selectedWorkspace.workspace_name}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <table className="clockify-table" >

              <thead className="clockify-table-header">
                <tr>
                  <th style={{ width: '50px', textAlign: 'center' }}>Sr.No</th>
                  <th>Task</th>
                  <th>Project</th>
                  <th>User</th>
                  <th>Date</th>
                  <th>Duration</th>
                </tr>
              </thead>
              <tbody>
                {(searchQuery ? filteredTimeEntries : timeEntries).map((entry, index) => (
                  <tr key={entry.id}>
                    <td style={{ textAlign: 'center' }}>
                      {paginationModel.page * paginationModel.pageSize + index + 1}
                    </td>
                    <td>
                      <div
                        className="task-name"
                        onClick={() => {
                          setSelectedTask(entry);
                          setShowTaskDialog(true);
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        {entry.description}
                      </div>
                    </td>
                    <td>
                      <div className="project-name" style={{
                        backgroundColor: entry.projectColor || '#2d4159',
                        color: '#e4e7ed',
                        padding: '4px 8px',
                        borderRadius: '4px',
                        display: 'inline-block'
                      }}>
                        {entry.projectName}
                      </div>
                    </td>
                    <td>
                      <div className="user-info" style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                        {userProfiles[entry.userId] ? (
                          <img
                            src={userProfiles[entry.userId]}
                            alt=""
                            style={{
                              width: '24px',
                              height: '24px',
                              borderRadius: '50%',
                              objectFit: 'cover'
                            }}
                          />
                        ) : (
                          <span style={{
                            width: '24px',
                            height: '24px',
                            borderRadius: '50%',
                            backgroundColor: '#1c8ffc',
                            color: 'white',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontSize: '12px'
                          }}>
                            {entry.userName.charAt(0).toUpperCase()}
                          </span>
                        )}
                        <span className="user-name" style={{ color: 'black' }}>{entry.userName}</span>
                      </div>
                    </td>
                    <td>
                      <div className="date-time-display" style={{ color: 'black' }}>
                        <div className="date" style={{ color: 'black' }}>
                          {new Date(entry.timeInterval.start).toLocaleDateString()}
                        </div>
                        <div className="time-range" style={{ color: 'black' }}>
                          {new Date(entry.timeInterval.start).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                          {' - '}
                          {new Date(entry.timeInterval.end).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="time-display duration">
                        {calculateDuration(entry.timeInterval.start, entry.timeInterval.end)}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div className="d-flex justify-content-between align-items-center mt-3">
          <div>
            Showing {paginationModel.page * paginationModel.pageSize + 1} to{' '}
            {Math.min((paginationModel.page + 1) * paginationModel.pageSize, totalEntries)} of {totalEntries} entries
          </div>
          <div className="btn-group">
            <button
              className="btn btn-outline-secondary"
              onClick={() => handlePageChange(paginationModel.page - 1)}
              disabled={paginationModel.page === 0}
            >
              Previous
            </button>
            {(() => {
              const totalPages = Math.ceil(totalEntries / paginationModel.pageSize);
              const currentPage = paginationModel.page;
              let startPage = Math.max(0, currentPage - 2);
              let endPage = Math.min(totalPages - 1, startPage + 4);

              // Adjust startPage if we're near the end
              if (endPage - startPage < 4) {
                startPage = Math.max(0, endPage - 4);
              }

              const pages = [];

              // Add first page if not in range
              if (startPage > 0) {
                pages.push(
                  <button
                    key="first"
                    className={`btn btn-outline-secondary`}
                    onClick={() => handlePageChange(0)}
                  >
                    1
                  </button>
                );
                if (startPage > 1) {
                  pages.push(
                    <button key="dots1" className="btn btn-outline-secondary" disabled>
                      ...
                    </button>
                  );
                }
              }

              // Add page numbers
              for (let i = startPage; i <= endPage; i++) {
                pages.push(
                  <button
                    key={i}
                    className={`btn ${paginationModel.page === i ? 'btn-primary' : 'btn-outline-secondary'}`}
                    onClick={() => handlePageChange(i)}
                  >
                    {i + 1}
                  </button>
                );
              }

              // Add last page if not in range
              if (endPage < totalPages - 1) {
                if (endPage < totalPages - 2) {
                  pages.push(
                    <button key="dots2" className="btn btn-outline-secondary" disabled>
                      ...
                    </button>
                  );
                }
                pages.push(
                  <button
                    key="last"
                    className={`btn btn-outline-secondary`}
                    onClick={() => handlePageChange(totalPages - 1)}
                  >
                    {totalPages}
                  </button>
                );
              }

              return pages;
            })()}
            <button
              className="btn btn-outline-secondary"
              onClick={() => handlePageChange(paginationModel.page + 1)}
              disabled={paginationModel.page >= Math.ceil(totalEntries / paginationModel.pageSize) - 1}
            >
              Next
            </button>
          </div>
        </div>
      </div>
      <Dialog
        header={null}
        visible={showTaskDialog}
        style={{ width: '400px' }}
        onHide={() => setShowTaskDialog(false)}
        className="task-dialog modern-dialog"
        showHeader={false}
      >
        {selectedTask && (
          <div className="task-details-modern">
            <div className="dialog-header">
              <h3>Task Details</h3>
              <button
                className="close-button"
                onClick={() => setShowTaskDialog(false)}
              >
                ×
              </button>
            </div>
            <div className="dialog-content">
              <div className="user-info">
                <span className="avatar">
                  {selectedTask.userName.charAt(0).toUpperCase()}
                </span>
                <span className="name">{selectedTask.userName}</span>
              </div>
              <div className="project-badge" style={{
                backgroundColor: selectedTask.projectColor || '#1c8ffc'
              }}>
                {selectedTask.projectName}
              </div>
              <div className="description-box">
                {selectedTask.description}
              </div>
            </div>
          </div>
        )}
      </Dialog>
    </div>
  );
};

export default ClockifyViewPage;
