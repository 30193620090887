/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import './LandingPage.css';
import Lottie from 'lottie-react';
import animationdat from './Animation - 1722938687914.json';
import animationdata3 from './Animation - 1722938763901.json';
import { ReactTyped } from 'react-typed';




const LandingPage = () => {
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const [activeNavLink, setActiveNavLink] = useState(null);

  const handleMobileNavToggle = () => {
    setMobileNavOpen((prevState) => !prevState);
  };
  const handleNavLinkClick = (navLink) => {
    setActiveNavLink(navLink);
    handleMobileNavToggle();
  };
  return (
    <div className={`container-fluid  bg-white ${mobileNavOpen ? 'mobile-nav-open' : ''}`} style={{ height: '100vh' }} >
   <header className='navbar'>
  <div className="nav-wrapper">
    <div className="logoLanding"  >
      <img  src="/images/LogoSpc.png" className='Landing-Page-class' alt="Company Logo" style={{}} />
    </div>
    <div className="navbar-container">
      <nav className="desktop-nav">
        <div className="nav-items">
          <a href="#Home" onClick={() => handleNavLinkClick('Home')} className={activeNavLink === 'Home' ? 'active' : ''}>Home</a>
          <a href="#mainHero" onClick={() => handleNavLinkClick('about')} className={activeNavLink === 'about' ? 'active' : ''}>About</a>
          <a href="#features" onClick={() => handleNavLinkClick('features')} className={activeNavLink === 'features' ? 'active' : ''}>Features</a>
          <a href='#Subscription Plans' onClick={() => handleNavLinkClick('Subscription Plans')} className={activeNavLink === 'Subscription Plans' ? 'active' : ''}>Pricing</a>
          <a href="#contact" onClick={() => handleNavLinkClick('contact')} className={activeNavLink === 'contact' ? 'active' : ''}>Contact Us</a>
          
        </div>
        <a
  href="/Login"
  className="login-button"
  style={{
    cursor: 'pointer',
    color: 'rgb(255, 255, 255)',
    borderRadius: '40px',
    padding: '10px 30px',
    position: 'relative',
    overflow: 'hidden',
    backgroundColor: 'black',
    textDecoration: 'none',
    display: 'inline-block',
    fontWeight: 'bold',
    fontSize: '1.2rem',
    transition: 'all 0.4s ease',
  }}
>
  Login
</a>
      </nav>
    </div>
    <div className="mobile-nav-icon" onClick={handleMobileNavToggle}>
      <i className="fas fa-bars"></i> {/* FontAwesome Bars icon */}
    </div>
    <nav className={`mobile-nav ${mobileNavOpen ? 'close' : ''}`} id='custom_nav_classs'>
      <a href="#about" onClick={handleMobileNavToggle}>About</a>
      <a href="#features" onClick={handleMobileNavToggle}>Features</a>
      <a href="#contact" onClick={handleMobileNavToggle}>Contact Us</a>
      <a href='/Login' onClick={handleMobileNavToggle}>Login</a>
    </nav>
  </div>
  <style jsx>{`
    .navbar {
      position: sticky;
      top: 0;
      width: 100%;
      background: #fff; /* or any background color */
      z-index: 1000; /* ensures it stays above other content */
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* optional: adds a shadow effect */
    }

    .mobile-nav {
      display: none;
    }

    @media (max-width: 768px) {
      .desktop-nav {
        display: none;
      }
      .mobile-nav {
        display: block;
      }
    }
  `}</style>
</header>



      <section className='row landingHero'  id='Home' style={{ fontFamily: 'system-ui' }}>

        <div className="col-md-6   text-center d-flex flex-column justify-content-center align-items-center hero-content">
          <h2 style={{ fontSize: '2.5em', marginBottom: '10px', fontWeight: 'bold',fontFamily: 'system-ui' }}>  Elevate <span style={{ color: '#3d1f91' }}><ReactTyped 
                    className='font-bold text-xl pl-2 md:font-bold md:text-5xl md:pl-2 sm:text-3xl'
                    strings={['Team','Workforce','Teamwork']} typeSpeed={180} backSpeed={180} loop 
                /></span> Productivity</h2>
          <p style={{ fontSize: '1.3em', color: '#555', marginBottom: '20px',fontFamily: 'system-ui' }}>Revolutionize your workflow with our cutting-edge solutions. From effortless attendance tracking to seamless leave management, experience a new era of workplace efficiency.</p>
          <a href="/Login" className='btn' onMouseEnter={(e) => { }} onMouseLeave={(e) => { e.target.style.backgroundColor = '#ffffff'; e.target.style.color = '#4b4efa'; }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="3em" height="3em" viewBox="0 0 24 24"><path fill="#020969" fillRule="evenodd" d="M13.204 2.244C11.347 1.826 10 3.422 10 5v14c0 1.578 1.347 3.174 3.204 2.756C17.666 20.752 21 16.766 21 12s-3.334-8.752-7.796-9.756m.089 6.049a1 1 0 0 1 1.414 0l3 3a1 1 0 0 1 0 1.414l-3 3a1 1 0 0 1-1.414-1.414L14.586 13H4a1 1 0 1 1 0-2h10.586l-1.293-1.293a1 1 0 0 1 0-1.414" clipRule="evenodd"></path></svg>
           
          </a>
        </div>

        <div className="col-md-6  mt-4 text-center">
          <Lottie animationData={animationdata3} />
        </div>
      </section>

      <div className="row service_section bg-white" id="mainHero" style={{ fontFamily: "system-ui",padding: '6rem 1rem'}}>
    <div className="row p-0 m-0 pt-3" id="about" style={{ marginBottom: '2rem' }}>
        <h2 className="d-flex justify-content-center align-items-center section_tab_title" style={{ fontSize: '28px', color: '#333', fontWeight: '700' }}>About Us</h2>
    </div>
    <div className="col-md-6 d-flex justify-content-center align-items-center" style={{ padding: '1rem' }}>
        <Lottie animationData={animationdat} style={{ width: '100%', maxWidth: '30rem' }} />
    </div>
    <div className="col-md-6" style={{ fontFamily: "system-ui" }}>
        <div className="content pt-4 pt-lg-4" style={{ padding: '1rem' }}>
            <h3 style={{ color: '#434175', fontSize: '2rem', fontWeight: '600', paddingTop: '5%',fontFamily: "system-ui" }}>Learn more about us</h3>
            <p className="fst-italic" style={{ color: '#555',fontSize: '24px', lineHeight: '1.6', margin: '1rem 0',fontFamily: "system-ui"}}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
            <ul style={{ listStyleType: 'none', paddingLeft: '0', marginBottom: '1rem' }}>
                <li style={{ marginBottom: '0.5rem' }}>
                    <i className="fas fa-check-circle" style={{ color: '#6366f1', marginRight: '0.5rem' ,fontFamily: "system-ui"}}></i>
                    <span style={{ color: '#333', lineHeight: '1.5',fontSize: '18px', }}>Ullamco laboris nisi ut aliquip ex ea commodo consequat.</span>
                </li>
                <li style={{ marginBottom: '0.5rem' }}>
                    <i className="fas fa-check-circle" style={{ color: '#6366f1', marginRight: '0.5rem',fontFamily: "system-ui" }}></i>
                    <span style={{ color: '#333', lineHeight: '1.5',fontSize: '18px', fontFamily: "system-ui"}}>Duis aute irure dolor in reprehenderit in voluptate velit.</span>
                </li>
                <li style={{ marginBottom: '0.5rem' }}>
                    <i className="fas fa-check-circle" style={{ color: '#6366f1', marginRight: '0.5rem',fontFamily: "system-ui" }}></i>
                    <span style={{ color: '#333', lineHeight: '1.5',fontSize: '18px',fontFamily: "system-ui" }}>Ullamco laboris nisi ut aliquip ex ea commodo consequat.</span>
                </li>
            </ul>
            <p style={{ color: '#555', lineHeight: '1.6',fontSize: '24px',fontFamily: "system-ui"  }}>
                Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate tera noden carma palorp mades tera.
            </p>
        </div>
    </div>
</div>


<div className="row service_section bg-white" id="features" style={{ fontFamily: "system-ui",padding: '6rem 1rem' }}>
  <div className="row">
    <h2
      className="d-flex justify-content-center align-items-center section_tab_title pt-3"
      style={{
        fontSize: '28px',
        fontWeight: 'bold',
        color: '#333',
        marginBottom: '30px',
      }}
    >
      Features
    </h2>
  </div>
  <div className="row" style={{flexDirection: 'row-reverse'}}>
    <div
      className="col-md-6 d-flex justify-content-center align-items-center "
      style={{
        padding: '20px',
        textAlign: 'center',
      }}
    >
      <img
        src="./images/Features.jpg"
        className="img-fluid rounded"
        alt="Features"
        style={{ maxWidth: '70%', height: 'auto' }}
      />
    </div>
    <div
      className="col-md-6 d-flex justify-content-center align-items-center"
      style={{
        padding: '20px',
      }}
    >
      <div className="row">
        <div className="col-md-6 mb-4">
          <div className="feature-box" style={{ textAlign: 'center' }}>
            <i
              className="fas fa-check icon"
              style={{
                fontSize: '20px',
                color: '#007bff',
                marginBottom: '15px',
              }}
            ></i>
            <h4 style={{ fontSize: '1.5rem', fontWeight: '600', marginBottom: '10px' }}>Attendance Tracking</h4>
            <p
              className="feature-description"
              style={{ fontSize: '1rem', color: '#555', lineHeight: '1.5' }}
            >
              Track attendance efficiently and manage employee presence seamlessly.
            </p>
          </div>
        </div>
        <div className="col-md-6 mb-4">
          <div className="feature-box" style={{ textAlign: 'center' }}>
            <i
              className="fas fa-check icon"
              style={{
                fontSize: '2rem',
                color: '#007bff',
                marginBottom: '15px',
              }}
            ></i>
            <h4 style={{ fontSize: '1.5rem', fontWeight: '600', marginBottom: '10px' }}>Late Arrival Monitoring</h4>
            <p
              className="feature-description"
              style={{ fontSize: '1rem', color: '#555', lineHeight: '1.5' }}
            >
              Monitor and manage late arrivals to ensure punctuality.
            </p>
          </div>
        </div>
        <div className="col-md-6 mb-4">
          <div className="feature-box" style={{ textAlign: 'center' }}>
            <i
              className="fas fa-check icon"
              style={{
                fontSize: '2rem',
                color: '#007bff',
                marginBottom: '15px',
              }}
            ></i>
            <h4 style={{ fontSize: '1.5rem', fontWeight: '600', marginBottom: '10px' }}>Leave Management</h4>
            <p
              className="feature-description"
              style={{ fontSize: '1rem', color: '#555', lineHeight: '1.5' }}
            >
              Handle leave requests and track leave balances effortlessly.
            </p>
          </div>
        </div>
        <div className="col-md-6 mb-4">
          <div className="feature-box" style={{ textAlign: 'center' }}>
            <i
              className="fas fa-check icon"
              style={{
                fontSize: '2rem',
                color: '#007bff',
                marginBottom: '15px',
              }}
            ></i>
            <h4 style={{ fontSize: '1.5rem', fontWeight: '600', marginBottom: '10px' }}>Holiday Calendar</h4>
            <p
              className="feature-description"
              style={{ fontSize: '1rem', color: '#555', lineHeight: '1.5' }}
            >
              View and manage holidays and special dates easily.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

      <div class="pricing-container" id="Subscription Plans" style={{ fontFamily: "system-ui",padding: '6rem 1rem' }}>
        <h1 class="title" style={{color: 'black', textAlign: 'center'}}>Subscription Plans</h1>
        <div class="pricing">
            <div class="pricing-box">
                <h2 class="plan-name">Free Plan</h2>
                <h3 class="price">$0 / mo</h3>
                
                <ul >
                    <li  >Aida dere</li>
                    <li>Nec feugiat nisl</li>
                    <li>Nulla at volutpat dola</li>
                    <li class="na">Pharetra massa</li>
                    <li class="na">Massa ultricies mi</li>
                </ul>
                <a href="#" class="btn-buy">Buy Now</a>
            </div>
            <div class="pricing-box featured">
                <h2 class="plan-name">Starter Plan</h2>
                <small class="small-colored">Best offer!</small>
                <h3 class="price">$19 / mo</h3>
                {/* <img src="assets/img/pricing-free.png" class="img-fluid" alt="Starter Plan" /> */}
                <ul>
                    <li>Aida dere</li>
                    <li>Nec feugiat nisl</li>
                    <li>Nulla at volutpat dola</li>
                    <li class="na">Pharetra massa</li>
                    <li class="na">Massa ultricies mi</li>
                </ul>
                <a href="#" class="btn-buy">Buy Now</a>
            </div>
            <div class="pricing-box">
                <h2 class="plan-name">Business Plan</h2>
                <h3 class="price">$29 / mo</h3>
                {/* <img src="assets/img/pricing-free.png" class="img-fluid" alt="Business Plan" /> */}
                <ul>
                    <li>Aida dere</li>
                    <li>Nec feugiat nisl</li>
                    <li>Nulla at volutpat dola</li>
                    <li >Pharetra massa</li>
                    <li class="na">Massa ultricies mi</li>
                </ul>
                <a href="#" class="btn-buy">Buy Now</a>
            </div>
            <div class="pricing-box">
                <h2 class="plan-name">Ultimate Plan</h2>
                <h3 class="price">$49 / mo</h3>
                {/* <img src="assets/img/pricing-free.png" class="img-fluid" alt="Ultimate Plan" /> */}
                <ul>
                    <li>Aida dere</li>
                    <li>Nec feugiat nisl</li>
                    <li>Nulla at volutpat dola</li>
                    <li class="na">Pharetra massa</li>
                    <li class="na">Massa ultricies mi</li>
                </ul>
                <a href="#" class="btn-buy">Buy Now</a>
            </div>
        </div>
    </div>

    <div className="row contact-us-landing bg-white p-md-5 p-2 pt-1 pt-md-2" id='contact' style={{ fontFamily: "system-ui",padding: '6rem 1rem' }}> 
    <div className="row" id='contact1'>
        <div className="col-12 section_tab_title d-flex justify-content-center align-center">Contact Us</div>
    </div>
    <div className="row d-flex justify-content-center align-center p-md-5">
        <div className="col-md-5 mb-2">
            <div className="row gy-2 mx-auto mx-2">
                <div className="col-md-12 contact_us_landing">
                    <div className="icon_box_landing" style={{ padding: '20px', backgroundColor: '#ede7f6', borderRadius: '8px' }}>
                        <i className="fas fa-map-marker-alt" style={{ fontSize: '2rem', color: '#3d1f91' }}></i>
                        <h4>Our Location</h4>
                        <p> 7RXJ+J8X, South Block, Fourth Floor, New OCAC Towers, Acharya Vihar, Bhubaneswar, Odisha 751013</p>
                    </div>
                </div>
                <div className="col-md-12 contact_us_landing">
                    <div className="icon_box_landing" style={{ padding: '20px', backgroundColor: '#ede7f6', borderRadius: '8px' }}>
                        <i className="fas fa-phone" style={{ transform: 'rotate(90deg)', fontSize: '2rem', color: '#3d1f91' }}></i>
                        <h4>Phone Number</h4>
                        <p>(+91)81 414 257 9980</p>
                    </div>
                </div>
                <div className="col-md-12 contact_us_landing">
                    <div className="icon_box_landing" style={{ padding: '20px', backgroundColor: '#ede7f6', borderRadius: '8px' }}>
                        <i className="fas fa-envelope" style={{ fontSize: '2rem', color: '#3d1f91' }}></i>
                        <h4>Email Address</h4>
                        <p>info@ajatus.co.in</p>
                    </div>
                </div>
            </div>
        </div>
        <div className="col-md-6 ml-md-5">
            <div className="contact-form p-md-5 p-4" style={{ backgroundColor: '#3d1f91', borderRadius: '8px', color: 'white' }}>
                <div className="mb-3">
                    <input type="text" className="form-control" placeholder="Your Name" style={{ borderRadius: '8px', border: 'none', padding: '15px' }} />
                </div>
                <div className="mb-3">
                    <input type="email" className="form-control" placeholder="Your Email" style={{ borderRadius: '8px', border: 'none', padding: '15px' }} />
                </div>
                <div className="mb-3">
                    <input type="text" className="form-control" placeholder="Your Phone" style={{ borderRadius: '8px', border: 'none', padding: '15px' }} />
                </div>
                <div className="mb-3">
                    <textarea className="form-control" rows="4" placeholder="Your Message" style={{ borderRadius: '8px', border: 'none', padding: '15px' }}></textarea>
                </div>
                <div className="text-center">
                    <button type="button" className="btn" style={{ backgroundColor: '#ff7675', borderRadius: '8px', color: 'white', padding: '15px 30px' }}>Send Message</button>
                </div>
            </div>
        </div>
    </div>
</div>

      <div className="row" style={{ backgroundColor: 'rgb(213 227 250 / 45%)', minHeight: '70px' }}>
        <div className="col-12 d-flex justify-content-center align-center m-auto" >
          <footer>
            <p style={{ color: 'rgb(67, 65, 117)', fontSize: '1rem' }}>&copy; 2024 Ajatus Software Pvt Ltd. All rights reserved.</p>
          </footer>
        </div>
      </div>
    </div>
  );
}

export default LandingPage;
