import axios from 'axios';

const api = axios.create({
  baseURL: 'https://apiwfms.ajatus.in/',
  // baseURL: 'http://localhost:4001/' // Set your API base URL here
});
// Function to set the Authorization header with the token
const setAuthToken = (token) => {
  if (token) {
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete api.defaults.headers.common['Authorization'];
  }
};
api.interceptors.response.use(

  (response) => {
    return response;
  },
  (error) => {

    if (error.response && error.response.status === 401) {
      localStorage.clear()
      window.location.href = '/Login';
    }
    return Promise.reject(error);
  }
);

// Initialize with an empty token
if (localStorage.getItem("token") !== undefined) {
  setAuthToken(localStorage.getItem("token"));
} else {
  setAuthToken('');
}
export { setAuthToken };
export default api;
