import React, { useState, useEffect } from 'react';
import { Form, Button, Row, Col, Card, InputGroup, Toast } from 'react-bootstrap';
import { FaPlus, FaTrashAlt } from 'react-icons/fa';
import api from "../../Authenticaltion/api";
import { useLoading } from '../../LoadingContext';
import { Calendar } from 'primereact/calendar';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

const GenerateSalarySlip = () => {
  const navigate = useNavigate();
  const { updateLoading } = useLoading();
  const [empList, setEmpList] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [basicInfo, setBasicInfo] = useState({
    employeeName: '',
    payDate: '',
    daysWorked: '',
    monthYear: '',
  });
  const [earnings, setEarnings] = useState([
    { key: 'Basic Salary', value: 0 },
    { key: 'Conveyance', value: 0 },
    { key: 'Medical', value: 0 },
    { key: 'HRA', value: 0 },
    { key: 'CCA', value: 0 },
    { key: 'Special Allowance', value: 0 },
    { key: 'Arrears', value: 0 },
    { key: 'Variable', value: 0 }
  ]);
  const [deductions, setDeductions] = useState([
    { key: 'EPF', value: 0 },
    { key: 'ESI', value: 0 },
    { key: 'PT', value: 0 },
    { key: 'Other Deductions', value: 0 },
    { key: 'TDS', value: 0 }
  ]);

  useEffect(() => {
    const fetchEmpList = async () => {
      try {
        const response = await api.get('/getEmployeeNameWithIdEmail');
        const employeeOptions = response.data.map(emp => ({
          value: emp.user_id,
          label: `${emp.Name} (${emp.Email_Id})`,
          details: emp
        }));
        setEmpList(employeeOptions);
      } catch (error) {
        console.error('Error fetching employee list', error);
      }
    };
    fetchEmpList();
  }, []);

  const handleEmployeeSelect = async (e) => {
    const selectedValue = e.target.value;
    console.log("Emp Value is ", e.target.value)
    console.log("Empl list is", empList)
    const selectedOption = empList.find(emp => emp.value == selectedValue);
    if (selectedOption) {
      console.log("Inside selected options come")
      updateLoading(true);
      setSelectedEmployee(selectedOption);
      try {
        const response = await api.get(`/getLatestMonthSalaryData/${selectedOption.value}`);
        const salaryDetails = response.data?.data || [];
        if (salaryDetails.length === 0) {
          setEarnings([
            { key: 'Basic Salary', value: 0 },
            { key: 'Conveyance', value: 0 },
            { key: 'Medical', value: 0 },
            { key: 'HRA', value: 0 },
            { key: 'CCA', value: 0 },
            { key: 'Special Allowance', value: 0 },
            { key: 'Arrears', value: 0 },
            { key: 'Variable', value: 0 }
          ]);
      
          setDeductions([
            { key: 'EPF', value: 0 },
            { key: 'ESI', value: 0 },
            { key: 'PT', value: 0 },
            { key: 'Other Deductions', value: 0 },
            { key: 'TDS', value: 0 }
          ]);
          // Handle case where no salary details are found
          return;
        }
        // Process salary details if needed
        const earningsData = salaryDetails.filter(item => item.type === 'Earning').map(item => ({
          key: item.category, // Use category or other identifier as the key
          value: item.amount // Store amount as a number (not a string)
        }));

        const deductionsData = salaryDetails.filter(item => item.type === 'Deduction').map(item => ({
          key: item.category, // Use category or other identifier as the key
          value: item.amount // Store amount as a number (not a string)
        }));

        // Update the state with the fetched earnings and deductions
        setEarnings(earningsData);
        setDeductions(deductionsData);

      } catch (error) {
        console.error("Error fetching employee salary details:", error);
        resetFormToDefaults();
      } finally {
        updateLoading(false);
      }
    } else {
      resetFormToDefaults();
    }
  };

  const resetFormToDefaults = () => {
    setSelectedEmployee(null);
    setBasicInfo({
      employeeName: '',
      payDate: '',
      daysWorked: '',
      monthYear: '',
      basicSalary: '',
    });
    setEarnings([
      { key: 'Basic Salary', value: 0 },
      { key: 'Conveyance', value: 0 },
      { key: 'Medical', value: 0 },
      { key: 'HRA', value: 0 },
      { key: 'CCA', value: 0 },
      { key: 'Special Allowance', value: 0 },
      { key: 'Arrears', value: 0 },
      { key: 'Variable', value: 0 }
    ]);

    setDeductions([
      { key: 'EPF', value: 0 },
      { key: 'ESI', value: 0 },
      { key: 'PT', value: 0 },
      { key: 'Other Deductions', value: 0 },
      { key: 'TDS', value: 0 }
    ]);
  };

  const handleBasicInfoChange = (e) => {
    const { name, value } = e.target;
    console.log("Name is", name);
    console.log("Value is", value);

    // Initialize the updated state
    let updatedState = { [name]: value };

    // Handling 'monthYear' field
    if (name === 'monthYear') {
      const selectedDate = new Date(value);
      const currentDate = new Date();

      // Check if the selected date is beyond the current date
      if (selectedDate > currentDate) {
        Swal.fire({
          icon: 'warning',
          text: 'Please select a month and year not beyond the current month!',
          showConfirmButton: false,
          timer: 2000,
        });
        updatedState = { ...updatedState, monthYear: '' };
      } else {
        const year = selectedDate.getFullYear();
        const month = selectedDate.getMonth(); // Months are zero-indexed
        const daysInMonth = new Date(year, month + 1, 0).getDate();
        const currentDayWorked = parseFloat(basicInfo.daysWorked);

        // Validate if the days worked exceed the days in the selected month
        if (!isNaN(currentDayWorked) && currentDayWorked > daysInMonth) {
          Swal.fire({
            icon: 'warning',
            text: `Day Worked cannot exceed ${daysInMonth} days for the selected month and year!`,
            showConfirmButton: false,
            timer: 1500,
          });
          updatedState = { ...updatedState, daysWorked: '' };
        }
      }
    }

    // Handling 'daysWorked' field
    if (name === 'daysWorked') {
      const currentMonth = new Date(basicInfo.monthYear).getMonth();
      const currentYear = new Date(basicInfo.monthYear).getFullYear();
      const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();
      console.log("Days in month is", daysInMonth);
      console.log("Days Worked is", value);
      console.log("Month Year is", currentMonth);

      // Check if 'daysWorked' exceeds the days in the selected month
      const currentDayWorked = parseFloat(value);
      if (!isNaN(currentDayWorked) && currentDayWorked > daysInMonth) {
        console.log("Days Worked is error come", value);
        Swal.fire({
          icon: 'warning',
          text: `Day Worked cannot exceed ${daysInMonth} days for the selected month and year!`,
          showConfirmButton: false,
          timer: 1500,
        });
        updatedState = { ...updatedState, daysWorked: '' };// Reset 'daysWorked' field
      }
    }

    // Update state once, for both 'monthYear' and 'daysWorked' or other fields
    setBasicInfo((prev) => {
      return { ...prev, ...updatedState };
    });

    console.log("Updated Basic Info is", updatedState);
  };



  const handleAddField = (type) => {
    if (type === 'earnings') {
      setEarnings([...earnings, { key: '', value: '' }]);
    } else {
      setDeductions([...deductions, { key: '', value: '' }]);
    }
  };

  const handleRemoveField = (type, index) => {

    if (type === 'earnings') {
      if (index === 0) return;
      setEarnings(earnings.filter((_, i) => i !== index));
    } else {
      setDeductions(deductions.filter((_, i) => i !== index));
    }
  };

  const handleFieldChange = (type, index, field, value) => {
    const updateFields = (fields) =>
      fields.map((item, i) => (i === index ? { ...item, [field]: value } : item));
    if (type === 'earnings') {
      setEarnings(updateFields(earnings));
    } else {
      setDeductions(updateFields(deductions));
    }
  };

  const handleGeneratePayslip = async () => {
    try {
      updateLoading(true);
      if (!selectedEmployee || !basicInfo.monthYear || !basicInfo || !basicInfo.daysWorked) {
        Swal.fire({
          icon: "warning",
          text: "Fill all mandatory fields!",
          showConfirmButton: false,
          timer: 1500
        });
        return;
      }
      if (selectedEmployee.details.Email_Id == null) {
        Swal.fire({
          icon: "warning",
          text: "Fill Basic Employee Details",
          showConfirmButton: false,
          timer: 1500
        });
        return;
      }
      if (earnings.length == 0 || deductions.length == 0) {
        Swal.fire({

        })
      }
      const payload = {
        user_id: selectedEmployee.value,
        employee_id: selectedEmployee.details.Emp_id,
        salary_month: new Date(basicInfo.monthYear).toLocaleString('default', { month: 'long' }),
        salary_year: new Date(basicInfo.monthYear).getFullYear(),
        earnings: earnings
          .filter(item => item.key.trim() !== '') // Filter out items where key is only spaces
          .map(item => ({
            earning_type: item.key.trim(), // Trim leading and trailing spaces from the key
            earning_amount: parseFloat(item.value) || 0,
          })),

        deductions: deductions
          .filter(item => item.key.trim() !== '') // Filter out items where key is only spaces
          .map(item => ({
            deduction_type: item.key.trim(), // Trim leading and trailing spaces from the key
            deduction_amount: parseFloat(item.value) || 0,
          })),
        day_worked: parseInt(basicInfo.daysWorked) || 0,
        // pay_date: basicInfo.payDate
      };
      console.log("Payload is", payload);

      const response = await api.post('/GeneratePayslip', payload);
      if (response.status === 201) {
        Swal.fire({
          icon: "success",
          text: "Salary Slip generated successfully!",
          showConfirmButton: false,
          timer: 1500
        });
        navigate('/Admin-Salaryslip');

      } else {
        let errorMessage = "Failed to generate salary slip!";
        if (response.data && response.data.error) {
          errorMessage = response.data.error;
        }
        Swal.fire({
          icon: "error",
          text: errorMessage,
          showConfirmButton: false,
          timer: 1500
        });
      }
    } catch (error) {
      console.error("Error generating salary slip:", error);
      let errorMessage = "Failed to generate salary slip!";
      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error;
      }
      Swal.fire({
        icon: "error",
        text: errorMessage,
        showConfirmButton: false,
        timer: 1500
      });
    } finally {
      updateLoading(false);
      resetFormToDefaults();
    }
  };

  return (
    <div className="generate-salary-slip">
      <Card>
        <Card.Body>
          <Form.Group controlId="employeeSelect">
            <Form.Label>Select Employee <span style={{ color: 'red' }}>*</span></Form.Label>
            <Form.Control
              as="select"
              value={selectedEmployee ? selectedEmployee.value : ''}
              onChange={handleEmployeeSelect}
            >
              <option value="">Choose an Employee</option>
              {empList.map((emp) => (
                <option key={emp.value} value={emp.value}>
                  {emp.label}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <Row className="mb-4">
            {/* <Col>
              <Form.Group controlId="payDate">
                <Form.Label>Pay Date</Form.Label>
                <Form.Control
                  type="date"
                  name="payDate"
                  value={basicInfo.payDate}
                  onChange={handleBasicInfoChange}
                />
              </Form.Group>
            </Col> */}
            <Col>
              <Form.Group controlId="daysWorked">
                <Form.Label>Days Worked <span style={{ color: 'red' }}>*</span>
                </Form.Label>
                <Form.Control
                  type="number"
                  name="daysWorked"
                  value={basicInfo.daysWorked}
                  onChange={(e) => {
                    const value = parseInt(e.target.value, 10);
                    if (value >= 1 || e.target.value === "") {
                      handleBasicInfoChange(e); // Only update if the value is valid or empty
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === '-' || e.key === 'e') e.preventDefault();
                  }}
                  min="1"
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="monthYear">
                <Form.Label>Month/Year <span style={{ color: 'red' }}>*</span></Form.Label>
                <Calendar
                  id="monthYear"
                  name="monthYear"
                  view="month"
                  dateFormat="mm/yy"
                  value={basicInfo.monthYear}
                  onChange={handleBasicInfoChange}
                  maxDate={new Date()}
                  style={{ width: '100%' }}
                />
              </Form.Group>
            </Col>

          </Row>

          <Row>
            <Col md={6}>
              <Card>
                <Card.Header>
                  Earnings
                  <Button
                    variant="success"
                    className="float-right"
                    onClick={() => handleAddField('earnings')}
                  >
                    <FaPlus />
                  </Button>
                </Card.Header>
                <Card.Body>
                  {earnings.map((earning, index) => (
                    <InputGroup className="mb-2" key={index}>
                      <Form.Control
                        placeholder="Earning Type"
                        value={earning.key}
                        onChange={(e) =>
                          handleFieldChange('earnings', index, 'key', e.target.value)
                        }
                        onKeyDown={(e) => {
                          // Allow letters, spaces, and Backspace, and prevent other keys
                          const isLetterOrSpace =
                            (e.key >= 'a' && e.key <= 'z') ||
                            (e.key >= 'A' && e.key <= 'Z') ||
                            e.key === ' ' ||
                            e.key === 'Backspace';
                          if (!isLetterOrSpace) e.preventDefault();
                        }}
                        disabled={index === 0}
                      />
                      <Form.Control
                        placeholder="Amount"
                        type="number"
                        value={earning.value}
                        onKeyDown={(e) => {
                          if (e.key === '-' || e.key === 'e') e.preventDefault();
                        }}
                        onChange={(e) =>
                          handleFieldChange('earnings', index, 'value', e.target.value)
                        }
                        min="0"
                      />
                      {index !== 0 && (  // Don't show the delete button for the first item
                        <Button
                          variant="danger"
                          onClick={() => handleRemoveField('earnings', index)}
                        >
                          <FaTrashAlt />
                        </Button>
                      )}
                    </InputGroup>
                  ))}
                </Card.Body>
              </Card>
            </Col>

            <Col md={6}>
              <Card>
                <Card.Header>
                  Deductions
                  <Button
                    variant="success"
                    className="float-right"
                    onClick={() => handleAddField('deductions')}
                  >
                    <FaPlus />
                  </Button>
                </Card.Header>
                <Card.Body>
                  {deductions.map((deduction, index) => (
                    <InputGroup className="mb-2" key={index}>
                      <Form.Control
                        placeholder="Deduction Type"
                        value={deduction.key}
                        onChange={(e) =>
                          handleFieldChange('deductions', index, 'key', e.target.value)
                        }
                      />
                      <Form.Control
                        placeholder="Amount"
                        type="number"
                        value={deduction.value}
                        onKeyDown={(e) => {
                          if (e.key === '-' || e.key === 'e') e.preventDefault();
                        }}
                        onChange={(e) =>
                          handleFieldChange('deductions', index, 'value', e.target.value)
                        }
                        min="0"
                      />
                      <Button
                        variant="danger"
                        onClick={() => handleRemoveField('deductions', index)}
                      >
                        <FaTrashAlt />
                      </Button>
                    </InputGroup>
                  ))}
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Button variant="primary" className="mt-4" onClick={handleGeneratePayslip}>
            Generate Payslip
          </Button>
        </Card.Body>
      </Card>
    </div>
  );
};

export default GenerateSalarySlip;
